.map-class {
    height: 100%;
    width: 100%;
}

.gm-control-active,
.gmnoprint {
    display: none !important;
}

.site-modal-button {
    background-color: $primary;    
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: rgba($color: $primary, $alpha: 0.8) !important;
}


.checkout-modal {
    position: fixed;
    justify-content: center;
    bottom: 0;
    z-index: 20;
    width: 100%;

    .checkout-card {
        margin: 15pt;
        width: 300pt;
    }

    .checkout-btn {
        background-color: $red_color;
        color: white;
        height: 40px;
    }

    .checkout-timer {
        font-size: 12pt;
        font-weight: 600;
        margin: 0pt;
    }

    .checkout-timer-text {
        margin: 0pt;
        font-weight: 400;
    }

    .checkout-title {
        font-size: 16pt;
        font-weight: 800;
        margin-top: 0pt;
        margin-bottom: 10pt;
    }
}

.qr-floating-icon {
    color: $primary;
    background-color: $dark_background !important;
    font-size: 18pt;
    padding: 3pt;
    background:
        linear-gradient(to right, $primary 2pt, transparent 2pt) 0 0,
        linear-gradient(to right, $primary 2pt, transparent 2pt) 0 100%,
        linear-gradient(to left, $primary 2pt, transparent 2pt) 100% 0,
        linear-gradient(to left, $primary 2pt, transparent 2pt) 100% 100%,
        linear-gradient(to bottom, $primary 2pt, transparent 2pt) 0 0,
        linear-gradient(to bottom, $primary 2pt, transparent 2pt) 100% 0,
        linear-gradient(to top, $primary 2pt, transparent 2pt) 0 100%,
        linear-gradient(to top, $primary 2pt, transparent 2pt) 100% 100%;
    background-repeat: no-repeat;
    background-size: 5pt 5pt;
    border-radius: 2pt;
}

.qr-floating-btn {
    right: 15pt;
    bottom: 15pt;
    width: 45pt;
    height: 45pt;
    background-color: $dark_background !important;

    .ant-float-btn-body, .ant-float-btn-content  {
        background-color: transparent !important;
        display: block;
        margin-top: 8pt;
        margin-right: 9pt;
    }
    
}

.location-permission-logo {
    width: 100%;
    margin: auto;
}

.location-permission-btn {
    width: 100%;
    height: 30pt;
    background-color: $primary;
    color: white;
}

.location-permission-title {
    width: 100%;
    text-align: center;
    margin-block: 10pt;
}

.location-permission-content {
    width: 100%;
    text-align: justify;
    margin-block: 10pt;
}

.ant-modal-confirm-body, .ant-modal-confirm-body-has-title {
    display: flex;
    justify-content: center;
}