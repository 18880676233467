$tab_header_width: calc((100vw - 10pt)/ 3);

.icon {
    font-size: 16pt;
}

.tab-title {
    color: $white;
    font-size: 12pt;
}

.ant-tabs-tab {
    width: $tab_header_width;
    margin: 0px !important;
    text-align: center;
    font-size: 14pt !important;
}

.ant-tabs-tab-btn {
    width: $tab_header_width;
}

.ant-tabs-tab-active {
    width: $tab_header_width;
    background-color: $primary !important;
    border-radius: 5pt;
    margin-block: 4pt !important;
    height: 30pt;
}

.ant-tabs-nav-list {
    background-color: $dark_background;
    padding-block: 2pt;
    padding-inline: 5pt;
}

.warning-message {
    text-align: center;
    font-weight: 600;
    color: #787777;
    margin-top: 50px;
}