$white : #FEFEFE;
$red_color: #F5222D;
$primary: #218fa9;
$background_color: #f1f1f1;
$dark_background: #001923;

body {
    letter-spacing: 0.4px;
    margin: 0 !important;
    font-family: 'Nunito' !important;
    background-color: $background_color;
    overflow-y: hidden;
    overflow-x: hidden;
}

.white-text {
    color: $white !important;
}

.content-height {
    height: calc(100vh - 50px) !important;
}

.flex-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: $dark_background;
    height: 70px;
}

.full-width {
    width: 100% !important;
}

.full-height {
    height: 100% !important;
}

.full-height-vh {
    height: 100vh !important;
}

.pad-hori {
    padding: 0 8px !important;
}

.pad-top {
    padding-top: 8px !important;
}

.pad-all {
    padding: 8px !important;
}

.pad-hori-lg {
    padding: 0 10pt !important;
}

.pad-all-lg {
    padding: 10pt !important;
}

.content-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.theme-max-width {
    max-width: 500px !important;
}

.flex {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.no-margin {
    margin: 0px !important;
}

.margin {
    margin: 8px !important;
}

.margin-top {
    margin-top: 8px !important;
}

.margin-bottom {
    margin-bottom: 8px !important;
}

.margin-left {
    margin-left: 8px !important;
}

.margin-right {
    margin-right: 8px;
}

.margin-bottom {
    margin-bottom: 8px;
}

.box-style {
    background-color: #FEFEFE;
    border: 1px solid #eee;
    box-shadow: 0px 2px 3px #ddd;
    border-radius: 2px;
}

.logo-wrapper {
    .main-menu {
        .ant-btn-icon {
            color: #fff;
        }

        display: none;
    }
}

.text-overflow-ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.border-radius {
    border-radius: 6px !important;
}

.bg-white {
    background-color: $white;
}

.error-color {
    color: #F5222D;
}

.warn-color {
    color: #FAAD14;
    ;
}

.success-color {
    color: #4CAF50;
}

.primary-color {
    color: $primary;
}

.error-background {
    background-color: #CC5454 !important;
}

.font-family {
    font-family: 'Nunito';
    margin: 0pt;
}