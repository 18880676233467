.login-form {
    background-color: $white;
    border-radius: 10pt;
    margin: 10pt;
}

.login-form-field {
    height: 36pt;
}

.login-form-field-icon {
    margin-inline: 3pt;
}

.login-button {
    background-color: $primary;
    height: 36pt;
}

.login-form-image {
    width: 60vw;
    max-width: 300pt;
    margin: auto;
    margin-bottom: 70pt;
    display: flex;
}