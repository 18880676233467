.scroll-view {
    // max-height: calc(100% - 0px);
    overflow-y: auto;
    overflow-x: hidden;

    &.no-title {
        max-height: 100% !important;
    }

    &.tabbed {
        max-height: 100% !important;
        padding-inline: 10pt !important;
    }
}

.scroll-content {
    overflow-y: scroll !important;
}

.view-layout-title {
    min-height: 50px;
    height: 50px;
}

.tab-view-layout {
    max-height: calc(100% - 50px);

    .ant-tabs-nav {
        padding: 0 8px;
        margin-bottom: 8px !important;
    }

    .ant-tabs-content-holder,
    .ant-tabs-content,
    .ant-tabs-tabpane {
        height: 100%;
    }
}

.inbox-title-bar {
    justify-content: space-between;
}