.slide-nav-drawer {
    background: $dark_background !important;
    text-align: center;
    padding: 10pt;
    border-radius: 0pt 0pt 10pt 10pt;

    .ant-menu {
        background: $dark_background !important;
    }

    .ant-drawer-title {
        color: #FFF !important;
        font-size: 18px !important;
    }

    .ant-drawer-header-title {
        flex-direction: row-reverse;
    }

    .ant-drawer-body {
        padding: 0px !important;
    }

    .ant-drawer-close {
        color: #FFF !important;
        margin-right: 0px !important;
    }

    .ant-drawer-footer {
        text-align: center;
        color: #c5cedcea;
    }

    .ant-menu-item-selected {
        background-color: $primary;
        width: 120pt;
        margin-inline: auto;
    }
}

.slide-nav-logo {
    max-width: 200pt;
    width: 70%;
}

.slide-nav-icon {
    font-size: 18px;
}

.logo-header {
    height: 30pt;
    margin-right: 10pt;
}

.version {
    margin: 0pt;
    color: #787777;
    font-size: 10pt;
    opacity: 0.6;
}

.slide-org-selector {
    max-width: 1000px;
    min-width: 250px;
    .ant-select-arrow {
        color: rgba(0, 0, 0, 0.88);
    }
}