/* HTML: <div class="loader"></div> */
.loader {
    width: 37.5pt;
    aspect-ratio: 1;
    display: grid;
    border: 3pt solid #0000;
    border-radius: 50%;
    border-right-color: $primary;
    animation: l15 1s infinite linear;
}

.loader::before,
.loader::after {
    content: "";
    grid-area: 1/1;
    margin: 1.5pt;
    border: inherit;
    border-radius: 50%;
    animation: l15 2s infinite;
}

.loader::after {
    margin: 8px;
    animation-duration: 3s;
}

@keyframes l15 {
    100% {
        transform: rotate(1turn)
    }
}